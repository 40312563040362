.root {
  @apply flex;
  @apply w-full;
  @apply flex-col;
  @apply min-h-full;
}

.root .container {
  @apply flex;
  @apply py-6;
  @apply gap-6;
  @apply h-full;
  @apply flex-col;
  @apply max-w-md;
  @apply flex-grow;
  @apply items-center;
  @apply justify-center;
}

.root .container .title {
  @apply text-xl;
  @apply text-center;
  @apply text-common;
  @apply font-semibold;
}

.root .container .duration {
  @apply flex;
  @apply gap-2;
  @apply text-sm;
  @apply text-common;
  @apply items-center;
}

.root .container .duration .icon {
  @apply text-primary;
}

.root .container .avatar {
  @apply flex-shrink-0;
}

.root .container .instructions {
  @apply [counter-reset:item];
  @apply flex;
  @apply gap-3;
  @apply w-full;
  @apply flex-col;
}

.root .container .instructions li {
  @apply flex;
  @apply text-common;
  @apply before:text-primary;
  @apply before:font-semibold;
  @apply [counter-increment:item];

  @apply before:w-6;
  @apply before:shrink-0;
  @apply before:content-[counter(item)'._'];
}
