.root {
  @apply flex;
  @apply w-full;
  @apply flex-col;
  @apply min-h-full;
}

.root .bgTop {
  @apply z-0;
  @apply fixed;
  @apply top-0;
  @apply right-0;
}

.root .top {
  @apply flex;
  @apply gap-6;
  @apply w-full;
  @apply flex-col;
  @apply items-center;
  @apply flex-shrink-0;
  @apply justify-center;
  @apply h-[calc(var(--vh,1vh)*40)];
  /* BORDER */
  @apply border-b;
  @apply border-dashed;
  @apply border-gray-200;
}

.root .top .like {
  @apply w-32;
  @apply h-32;
  @apply flex;
  @apply relative;
  @apply border-2;
  @apply shrink-0;
  @apply rounded-full;
  @apply items-center;
  @apply justify-center;
  @apply border-success;
  /* BEFORE */
  @apply before:w-full;
  @apply before:h-full;
  @apply before:border;
  @apply before:absolute;
  @apply before:content-[''];
  @apply before:rounded-full;
  @apply before:animate-ping;
  @apply before:border-success;
}

.root .top .text {
  @apply text-xl;
  @apply text-success;
  @apply font-semibold;
}

.root .bottom {
  @apply flex;
  @apply py-6;
  @apply flex-col;
  @apply flex-grow;
}

.root .bottom .results {
  @apply flex;
  @apply flex-col;
}

.root .bottom .results .title {
  @apply flex;
  @apply px-6;
  @apply gap-2;
  @apply text-common;
  @apply items-center;
  @apply font-semibold;
}

.root .bottom .results .title .icon {
  @apply w-6;
  @apply h-6;
  @apply text-primary;
}

.root .bottom .results .badges {
  @apply pt-3;
  @apply flex;
  @apply flex-col;
}

.root .bottom .message {
  @apply mt-2;
  @apply mx-6;
  @apply text-sm;
  @apply relative;
  @apply flex-grow;
  @apply text-common;
  @apply md:mx-16;
}

.root .bottom .message .speech {
  @apply relative;
  @apply translate-x-0;
  @apply translate-y-0;
  @apply rounded-full;
  @apply shadow-md;

  @apply md:absolute;
  @apply md:-left-12;
  @apply md:-top-1;
}

.root .bottom .buttons {
  @apply mt-6;
  @apply flex;
  @apply gap-4;
  @apply mx-auto;
  @apply flex-col;
  @apply items-center;
}
