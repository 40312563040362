.root {
  @apply flex;
  @apply w-full;
  @apply static;
  @apply flex-col;
  @apply my-auto;
  @apply shadow-none;
}

.root .head .back {
  @apply left-2;
  @apply absolute;
}

.root .container {
  @apply mb-10;
  @apply md:px-16;
}

.root .container .title {
  @apply mb-4;
  @apply w-full;
  @apply text-lg;
  @apply text-common;
  @apply font-semibold;
}

.root .container .required {
  @apply mb-8;
  @apply text-xs;
  @apply text-gray-500;
}

.root .container .form {
  @apply grid;
  @apply w-full;
  @apply gap-y-4;
  @apply gap-x-10;
}

.root .container .form .title {
  @apply mt-6;
  @apply pl-4;
  @apply relative;
  @apply font-semibold;
  @apply text-sm text-common;

  @apply before:w-0.5;
  @apply before:h-full;
  @apply before:left-0;
  @apply before:absolute;
  @apply before:bg-primary;
  @apply before:content-[''];
}

.root .container .form .button {
  @apply mt-6;
  @apply mx-auto;
  @apply col-span-full;
}
