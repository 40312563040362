.root {
  @apply flex;
  @apply flex-col;
  @apply min-h-screen;
}

.root .container {
  @apply grid;
  @apply px-0;
  @apply flex-grow;
}
