.root {
  @apply flex;
  @apply px-6;
  @apply py-3;
  @apply mb-8;
  @apply w-full;
  @apply items-center;
  @apply bg-primary/10;
  @apply justify-between;
}

.root .content {
  @apply flex;
  @apply flex-col;
}

.root .content .title {
  @apply text-primary;
  @apply font-semibold;
}

.root .content .description {
  @apply text-sm;
  @apply text-common;
}

.root .content .threshold {
  @apply text-sm;
  @apply text-common;
}

.root .circle {
  @apply flex;
  @apply w-24;
  @apply h-24;
  @apply -mb-8;
  @apply relative;
  @apply border-4;
  @apply bg-white;
  @apply rounded-full;
  @apply items-center;
  @apply flex-shrink-0;
  @apply border-accent;
  @apply justify-center;
  @apply overflow-hidden;
  /* BEFORE */
  @apply before:z-0;
  @apply before:w-full;
  @apply before:h-full;
  @apply before:absolute;
  @apply before:content-[''];
  /* COLORS */
  @apply data-[color='green']:border-success;
  @apply data-[color='green']:before:bg-success/80;
  @apply data-[color='yellow']:border-yellow-500;
  @apply data-[color='yellow']:before:bg-yellow-500/80;
  @apply data-[color='orange']:border-orange-500;
  @apply data-[color='orange']:before:bg-orange-500/80;
}

.root .circle .label {
  @apply z-10;
  @apply text-xs;
  @apply relative;
  @apply text-white;
  @apply text-center;
  @apply font-semibold;
}
